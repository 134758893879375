// src/screens/Dashboard.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from 'recharts';
import axios from 'axios';
import { Circle } from '@mui/icons-material'; // Import dell'icona di un cerchio
import { styled } from '@mui/material/styles';

const StyledStatus = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
}));

const StatusIndicator = ({ status }) => {
  const isActive = status === 'In corso';
  return (
    <StyledStatus>
      <Circle
        style={{
          color: isActive ? 'green' : 'red',
          fontSize: '1rem',
        }}
      />
      <span>{status}</span>
    </StyledStatus>
  );
};

// Funzione per ottenere il primo giorno del mese
const getFirstDayOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth(), 1); // Il primo giorno del mese
};

// Funzione per ottenere l'ultimo giorno del mese
const getLastDayOfMonth = () => {
  const now = new Date();
  return new Date(now.getFullYear(), now.getMonth() + 1, 0); // L'ultimo giorno del mese
};

const formatDateToLocal = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Mese da 0 a 11, aggiungi 1
  const day = String(date.getDate()).padStart(2, '0'); // Giorno del mese
  return `${year}-${month}-${day}`; // Formato 'yyyy-MM-dd'
};

const Dashboard = () => {
  const [usageData, setUsageData] = useState([]);
  const [averageTime, setAverageTime] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
  });
  const [totalPrice, setTotalPrice] = useState(0);
  const [pitchUsageData, setPitchUsageData] = useState([]);
  const [recentStops, setRecentStops] = useState([]);
  const [preferredArrivalTimes, setPreferredArrivalTimes] = useState([]);
  const [startDate, setStartDate] = useState(
    formatDateToLocal(getFirstDayOfMonth())
  );
  const [endDate, setEndDate] = useState(
    formatDateToLocal(getLastDayOfMonth())
  );
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if (startDate && endDate) {
      fetchData();
    }
  }, [startDate, endDate]);

  const fetchData = async () => {
    try {
      const token = localStorage.getItem('adminToken');
      const response = await axios.get(`${apiUrl}/admins/dashboard-data`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate,
          endDate,
        },
      });

      setUsageData(response.data.usageData);
      setAverageTime(response.data.averageTime);
      setTotalPrice(response.data.totalPrice);
      setPitchUsageData(response.data.pitchUsageData);
      setRecentStops(response.data.recentStops);
      setPreferredArrivalTimes(response.data.preferredArrivalTimes);
    } catch (error) {
      console.error('Error fetching dashboard data:', error);
    }
  };

  const handleStartDateChange = (e) => {
    const value = e.target.value;
    const selectedDate = new Date(value);
    // Imposta l'ora a 00:00
    if (value && value.length === 10) {
      setStartDate(formatDateToLocal(selectedDate)); // Usa il formato locale
    }
  };

  const handleEndDateChange = (e) => {
    const value = e.target.value;
    const selectedDate = new Date(value);
    // Imposta l'ora a 23:59
    if (value && value.length === 10) {
      setEndDate(formatDateToLocal(selectedDate)); // Usa il formato locale
    }
  };

  // Funzione per aprire il selettore di date manualmente
  const handleOpenDatePicker = (e) => {
    e.target.showPicker(); // Usa l'API nativa showPicker()
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  // Funzione per determinare lo stato della prenotazione
  const getStatus = (endTime) => {
    const now = Date.now() / 1000; // Tempo attuale in secondi
    return endTime > now ? 'In corso' : 'Scaduta';
  };

  // Funzione per calcolare il numero di giorni della sosta

  const calculateDays = (startTime, endTime) => {
    const startDate = new Date(startTime * 1000);
    const endDate = new Date(endTime * 1000);
    const differenceInTime = endDate - startDate; // Differenza in millisecondi
    return Math.ceil(differenceInTime / (1000 * 60 * 60 * 24)); // Converti in giorni
  };

  const formatDate = (unixTime) => {
    const date = new Date(unixTime * 1000);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const getPaymentMethodLabel = (method) => {
    switch (method) {
      case 'bonifico':
        return 'Bonifico';
      case 'contanti':
        return 'Contanti';
      case 'carta':
        return 'Carta di Credito';
      default:
        return 'Non Specificato';
    }
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h4">Dashboard</Typography>
            <Box display="flex" alignItems="center">
              <TextField
                label="Data Inizio"
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                InputLabelProps={{ shrink: true }}
                size="small"
                sx={{ mr: 2 }}
                onClick={handleOpenDatePicker}
                InputProps={{
                  onKeyDown: (e) => e.preventDefault(), // Previene la digitazione manuale
                }}
              />
              <TextField
                label="Data Fine"
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                InputLabelProps={{ shrink: true }}
                size="small"
                onClick={handleOpenDatePicker}
                InputProps={{
                  onKeyDown: (e) => e.preventDefault(), // Previene la digitazione manuale
                }}
              />
            </Box>
          </Box>
        </Grid>

        {/* Altri elementi della dashboard */}
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Tempo medio di sosta</Typography>
            <Typography variant="h4">
              {averageTime.days} {averageTime.days === 1 ? 'giorno' : 'giorni'}{' '}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Prezzo totale delle soste</Typography>
            <Typography variant="h4">€ {totalPrice.toFixed(2)}</Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Piazzola più usata</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={usageData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {usageData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend
                  layout="vertical"
                  verticalAlign="middle"
                  align="right"
                />
              </PieChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Piazzola più prenotata</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={pitchUsageData}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  domain={[0, 'auto']}
                  tickFormatter={(tick) => (Number.isInteger(tick) ? tick : '')}
                  allowDecimals={false}
                />
                <Tooltip />
                <Legend />
                <Bar dataKey="value" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Orario preferito di arrivo</Typography>
            <ResponsiveContainer width="100%" height={300}>
              <BarChart
                data={preferredArrivalTimes}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="count" fill="#8884d8" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="h6">Ultime 5 soste</Typography>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>Cognome</TableCell>
                    <TableCell>Telefono</TableCell>
                    <TableCell>Targa</TableCell>
                    <TableCell>Piazzola</TableCell>
                    <TableCell>Data inizio</TableCell>
                    <TableCell>Data fine</TableCell>
                    <TableCell>Prezzo</TableCell>
                    <TableCell>Stato</TableCell>
                    <TableCell>Giorni Sosta</TableCell>
                    <TableCell>Pagamento</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {recentStops.map((stop) => (
                    <TableRow key={stop.id}>
                      <TableCell>{stop.users.firstName}</TableCell>
                      <TableCell>{stop.users.lastName}</TableCell>
                      <TableCell>{stop.users.phone}</TableCell>
                      <TableCell>{stop.vehicles.licensePlate}</TableCell>
                      <TableCell>{stop.restareas.friendlyName}</TableCell>
                      <TableCell>{formatDate(stop.start)}</TableCell>
                      <TableCell>{formatDate(stop.end)}</TableCell>
                      <TableCell>{stop.price} €</TableCell>
                      <TableCell>
                        <StatusIndicator status={getStatus(stop.end)} />
                      </TableCell>
                      <TableCell>
                        {calculateDays(stop.start, stop.end)}
                      </TableCell>
                      <TableCell>{getPaymentMethodLabel(stop.paymentType)}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Dashboard;
