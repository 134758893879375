// src/App.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AdminLogin from './screens/AdminLogin.js';
import PrivateRoute from './components/PrivateRoute.js';
import Bookings from './screens/Bookings.js';
import StopZones from './screens/StopZones.js';
import RestAreas from './screens/RestAreas.js';
import Dashboard from './screens/Dashboard.js';
import Sidebar from './components/Sidebar.js';
import { Box, Toolbar } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { generateTheme } from './theme.js';

const App = () => {
  
  const [theme, setTheme] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(
    !!localStorage.getItem('adminToken')
  );

  useEffect(() => {
    const token = localStorage.getItem('adminToken');
    setIsAuthenticated(!!token);
    const fetchTheme = async () => {
      const theme = await generateTheme();
      setTheme(theme);
    };
    fetchTheme();
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('adminToken');
    setIsAuthenticated(false);
  };

  if (!theme) return null;

  return (
    <ThemeProvider theme={theme}>
      
      <Router>
      
        <Box sx={{ display: 'flex' }}>
          {isAuthenticated && <Sidebar onLogout={handleLogout} />}
          {console.log('theme:', theme)}
          <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {isAuthenticated && <Toolbar />}
            <Routes>
              <Route
                path="/login"
                element={<AdminLogin setIsAuthenticated={setIsAuthenticated} />}
              />
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/bookings"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <Bookings />
                  </PrivateRoute>
                }
              />
              <Route
                path="/stopzones"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <StopZones />
                  </PrivateRoute>
                }
              />
              <Route
                path="/stopzones/:id"
                element={
                  <PrivateRoute isAuthenticated={isAuthenticated}>
                    <RestAreas />
                  </PrivateRoute>
                }
              />
              {/* Aggiungi altre rotte per gli utenti */}
            </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeProvider>
  );
};

export default App;
